
            @import "@/assets/sass/vars.portal.scss";
          






























































































































































































































































































































































































































































































































































































.table-responsive {
    overflow-x: auto !important;
}
